import { fork } from 'redux-saga/effects';

import { actions } from 'domain/medium';
import { itemNavigator } from 'sagas/common';
import { editableFields } from './index';
import { loadByName } from 'domain/dictionary/sagas';
import { dictionariesList } from 'domain/dictionary';

export default function* navigator(args) {
  yield fork(itemNavigator, { modelName: 'Medium', actions, editableFields }, args);
  yield fork(loadByName,'mediums',dictionariesList['mediums']);
}
